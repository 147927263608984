import auth0 from 'auth0-js'

const requestedScopes = [
  'show:dashboard',
  'show:aanvragen',
  'show:deelnemer_opdrachten',
  'show:opdrachten',
  'show:activiteiten',
  'show:bijlage_assigned',
  'show:projectmeldingen',
  'show:projectdossiers',
  'show:contracten',
  'show:planning',
  'show:klant',
  'show:klant_vragen',
  'show:quickscan_melden',
  'show:combiuitvoering_melden',
  'show:actief_melden',
  'show:update_labels',
  'create:activiteiten',
  'create:event_herplanning',
  'create:event_herplanning_klant',
  'show:create_assigned_bijlage',
  'create:projectdossier',
  'create:project',
  'create:event_hoofdleiding_disciplines',
  'create:event_hoofdleiding_aannemer',
  'create:event_hoofdleiding_processtap',
  'create:event_hoofdleiding_details',
  'switch:user_param',
  'show:beheer',
  'show:import_aanvraag',
  'show:gebiedswissel',
  'copy:aanvraag',
]

export function getAuth0Options({ hostname, origin }) {
  const production = 'capo.dsplatform.nl'
  const options = {
    clientID: '5rUVtZ1go0R40fNsJQNGf6X0sU92x5Ud',
    domain: 'dspanalytics.eu.auth0.com',
    returnTo: origin + '/home',
    redirectUri: origin + '/callback',
    audience: 'https://capo.dsplatform.nl/rest',
    responseType: 'token id_token',
    scope: `openid profile email ${requestedScopes.join(' ')}`,
  }

  if (hostname !== production) {
    options.clientID = 'jVG2koCpugAfabADcxwmg2fwdL7lTscq'
    options.domain = 'test-dspanalytics.eu.auth0.com'
  }

  return options
}

let client

const auth0Client = () => {
  if (!client) client = new auth0.WebAuth(getAuth0Options(window.location))

  return client
}

export const checkSession = (callback) => {
  auth0Client().checkSession({ state: window.location.pathname }, callback)
}

export const parseHash = (callback) => {
  auth0Client().parseHash(callback)
}

export const login = () => {
  auth0Client().authorize({ state: window.location.pathname })
}

export const logout = () => {
  auth0Client().logout({ returnTo: auth0Client().baseOptions.returnTo })
}
