import type { Event, EventType } from '@models/Event'
import { formatDate, formatDateTime } from '@util/date-util'

import type { QuickscanQuestion } from '../../aansluiting/project/types'

export const eventTypeMap: Record<EventType, string> = {
  ['combiuitvoering']: 'Combi uitvoering',
  ['quickscan']: 'Quickscan',
  ['actief']: 'Actief',
  ['product-annulering']: 'Product annulering',
  ['herplanning']: 'Herplanning',
  ['herplanning_klant']: 'Herplanning klant',
  ['hoofdleiding_aanleggen']: 'Hoofdleiding aanleggen',
  ['hoofdleiding_disciplines']: 'Hoofdleiding disciplines',
  ['hoofdleiding_aannemer']: 'Hoofdleiding aannemer',
  ['update_labels']: 'Update labels',
  ['hoofdleiding_processtap']: 'Hoofdleiding processtap',
  ['hoofdleiding_details']: 'Hoofdleiding details',
}

export const eventTitleMap: Record<EventType, string> = {
  ['combiuitvoering']: 'Combi uitvoering',
  ['quickscan']: 'Quickscan',
  ['actief']: 'Actief',
  ['product-annulering']: 'Annuleren discipline',
  ['herplanning']: 'Herplanning',
  ['herplanning_klant']: 'Herplanning aanvraag',
  ['hoofdleiding_aanleggen']: 'Aanleggen hoofdleiding',
  ['hoofdleiding_disciplines']: 'Wijzigen disciplines',
  ['hoofdleiding_aannemer']: 'Wijzigen aannemer',
  ['update_labels']: 'Wijzigen labels',
  ['hoofdleiding_processtap']: 'Wijzigen processtap',
  ['hoofdleiding_details']: 'Wijzigen project details',
}

export const questionIdMap: Record<QuickscanQuestion['id'], string> = {
  ['combiuitvoering']: 'Mee in combi',
  ['Extra werkvoorbereiding']: 'Extra werkvoorbereiding',
  ['Hoofdleiding aanleggen']: 'Hoofdleiding aanleggen',
  ['hoofdleiding_aanleggen']: 'Hoofdleiding aanleggen',
}

export const generateEventDescription = ({
  created,
  data,
  source,
  type,
  email,
}: Event): {
  title: string
  caption: string
} => {
  const { deelnemer, organisatie } = data

  let caption = `${formatDateTime(created)} door ${
    organisatie || deelnemer || source
  }`

  if (email) {
    caption = `${caption} ${email}`
  }

  switch (type) {
    case 'combiuitvoering': {
      if ('isCombiUitvoering' in data) {
        const { isCombiUitvoering } = data

        return {
          title: `${isCombiUitvoering ? 'In' : 'Uit'} combi`,
          caption,
        }
      } else if ('disciplines' in data) {
        return {
          title: 'Combi uitvoering per discipline',
          caption,
        }
      } else {
        return {
          title: 'Combi uitvoering',
          caption,
        }
      }
    }

    case 'quickscan': {
      return {
        title: `Quickscan`,
        caption,
      }
    }

    case 'actief': {
      const { isActive } = data
      return {
        title: `${isActive ? 'Geactiveerd' : 'Gedeactiveerd'}`,
        caption,
      }
    }

    case 'product-annulering': {
      return {
        title: `Discipline geannuleerd`,
        caption,
      }
    }

    case 'herplanning': {
      const { startUitvoeringWeek } = data
      return {
        title: `Herplanning naar week ${startUitvoeringWeek}`,
        caption,
      }
    }

    case 'herplanning_klant': {
      const { geplandeUitvoeringsdatum } = data
      return {
        title: `Herplanning naar ${formatDate(geplandeUitvoeringsdatum)}`,
        caption,
      }
    }

    case 'hoofdleiding_aanleggen': {
      return {
        title: `Aanleggen hoofdleiding`,
        caption,
      }
    }

    case 'hoofdleiding_disciplines': {
      return {
        title: 'Wijzigen disciplines',
        caption,
      }
    }

    case 'hoofdleiding_aannemer': {
      const { aannemer } = data
      return {
        title: `Wijzigen aannemer naar ${aannemer}`,
        caption,
      }
    }

    case 'update_labels': {
      return {
        title: 'Wijzigen labels',
        caption,
      }
    }

    case 'hoofdleiding_processtap': {
      const { statusText } = data
      return {
        title: `Wijzigen processtap naar ${statusText}`,
        caption,
      }
    }

    case 'hoofdleiding_details': {
      return {
        title: `Wijzigen project details`,
        caption,
      }
    }

    default: {
      return { title: 'Onbekend type', caption: 'Onbekend type' }
    }
  }
}

export const getAvailableTypes = (events: Event[]): EventType[] =>
  Array.from(new Set(events.map((event) => event.type)))
