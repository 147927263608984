import { createSlice } from '@reduxjs/toolkit'

import { setAccessToken, setUserParam } from '../gateways/api'
import type { RootState } from '../store'

import type { AuthenticatedUser, Profile, State } from './types'

export const authenticatedUser = ({
  grantedScopes,
  profile,
}: {
  grantedScopes: string[]
  profile: Profile | Record<string, any>
}): AuthenticatedUser => {
  const hasScopes = (scopes: string[]) =>
    scopes.every((scope) => grantedScopes.includes(scope))

  const combi = profile['http://capo.dsplatform.nl/combi']
  const deelnemer = profile['http://analytics.dsplatform.nl/deelnemer']
  const delegerende = profile['http://analytics.dsplatform.nl/delegerende']

  const organisatie = (combi || delegerende || deelnemer) as string

  return {
    name: profile.name,
    picture: profile.picture,
    combi,
    deelnemer,
    delegerende,
    organisatie,
    // View
    canViewDashboard: hasScopes(['show:dashboard']),
    canViewProjecten: hasScopes(['show:aanvragen']),
    canViewOpdrachten: hasScopes(['show:deelnemer_opdrachten']),
    canViewCombiOpdrachten: hasScopes(['show:opdrachten']),
    canViewActiviteiten: hasScopes(['show:activiteiten']),
    canViewAssignedBijlagen: hasScopes(['show:bijlage_assigned']),
    canViewProjectmeldingen: hasScopes(['show:projectmeldingen']),
    canViewProjectdossiers: hasScopes(['show:projectdossiers']),
    canViewContracten: hasScopes(['show:contracten']),
    canViewPlanning: hasScopes(['show:planning']),
    canViewKlantgegevens: hasScopes(['show:klant']),
    canViewKlantVraagAntwoord: hasScopes(['show:klant_vragen']),
    canViewQuickScan: hasScopes(['show:quickscan_melden']),
    canViewCombiUitvoeringMelden: hasScopes(['show:combiuitvoering_melden']),
    canViewActiefMelden: hasScopes(['show:actief_melden']),
    canViewUpdateLabels: hasScopes(['show:update_labels']),
    // Create
    canCreateActiviteit: hasScopes(['create:activiteiten']),
    canCreateHerplanning: hasScopes(['create:event_herplanning']),
    canCreateHerplanningKlant: hasScopes(['create:event_herplanning_klant']),
    canCreateAssignedBijlage: hasScopes(['show:create_assigned_bijlage']),
    canCreateProjectdossier: hasScopes(['create:projectdossier']),
    canCreateProject: hasScopes(['create:project']),
    canChangeProject: hasScopes(['create:event_hoofdleiding_details']),
    canCreateWijzigenDisciplines: hasScopes([
      'create:event_hoofdleiding_disciplines',
    ]),
    canCreateWijzigenAannemer: hasScopes([
      'create:event_hoofdleiding_aannemer',
    ]),
    canCreateProcesstap: hasScopes(['create:event_hoofdleiding_processtap']),
    switchUserParam: hasScopes(['switch:user_param']),
    canViewBeheer: hasScopes(['show:beheer']),
    canViewGebiedswissel: hasScopes(['show:gebiedswissel']),
    canViewAanvraagImporteren: hasScopes(['show:import_aanvraag']),
    canViewAanvraagKopieren: hasScopes(['copy:aanvraag']),
  }
}

const initialState: State = {
  profile: {},
  expiresAt: undefined,
  accessToken: undefined,
  scopes: [],
  sessionChecked: false,
  userParam: '',
  user: {},
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeToken: (state, action) => {
      const { expiresIn, accessToken, scope, idTokenPayload } = action.payload
      const millis = 1000

      state.expiresAt = expiresIn * millis + new Date().getTime()
      state.user = authenticatedUser({
        grantedScopes: scope.split(' '),
        profile: idTokenPayload,
      })

      setAccessToken(accessToken)
    },
    storeSessionChecked: (state, action) => {
      state.sessionChecked = action.payload
    },
    storeUserParam: (state, action) => {
      state.userParam = action.payload
      setUserParam(state.userParam)
    },
  },
})

export const isSessionChecked = (state: RootState) => state.auth.sessionChecked
export const isAuthenticated = (state: RootState) =>
  state.auth.expiresAt && new Date().getTime() < state.auth.expiresAt
export const getUser = (state: RootState) => state.auth.user
export const getUserParam = (state: RootState) => state.auth.userParam

export const { reducer } = slice
export const { storeToken, storeSessionChecked, storeUserParam } = slice.actions
